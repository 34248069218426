// theme select
.theme-select {
  width: 125px;
  height: 36px;
  .mat-form-field-infix {
    padding: 6px 0 1px !important;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-color: $borderColor;
  }
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: $borderColor;
  }
  .mat-mdc-form-field-flex {
    height: 36px;
    align-items: center;
  }
}

.timeline {
  position: relative;
  .timeline-item {
    position: relative;
    height: 70px;
    .time {
      padding: 6px 16px 6px 0;
      min-width: 90px;
      flex-shrink: 0;
    }
    .desc {
      padding: 6px 16px;
    }
    .timline-border {
      width: 1px;
      height: 100%;
      background-color: $borderColor;
      flex-shrink: 0;
    }

    &:last-child {
      height: 25px;
    }
    .point {
      flex-direction: column;
      .timeline-badge {
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: transparent;
        flex-shrink: 0;
        &.border-primary {
          border: 2px solid $primary !important;
        }
        &.border-accent {
          border: 2px solid $accent !important;
        }
        &.border-success {
          border: 2px solid $success;
        }
        &.border-warning {
          border: 2px solid $warning;
        }
        &.border-error {
          border: 2px solid $error;
        }
      }

      .timeline-border {
        width: 1px;
        height: 100%;
        background-color: $borderColor;
        flex-shrink: 0;
      }
    }
    &:last-child {
      .timeline-border {
        display: none !important;
      }
    }
  }
}

.user-category {
  margin-top: -45px;
  z-index: 1;
  position: relative;
}

.card-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 242px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.cart-btn {
  position: absolute !important;
  right: 16px;
  top: -21px;
}

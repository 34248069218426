$mat-primary: (
  100: #e290a5,
  500: #D8315B,
  700: #66172b,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$suceess: (
  100: #d0f0c0,
  500: #7ccf50,
  700: #3a7d1e,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-secondary: (
  100: #dbe8fc,
  500: #7c8fac,
  700: #2a3447,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-accent: (
  100: #b9d8f5,
  500: #45A6FF,
  700: #003d75,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-warn: (
  100: #f3e1c9,
  500: #f8a85d,
  700: #c9782d,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$theme-primary: mat.define-palette($mat-primary, 500);
$theme-secondary: mat.define-palette($mat-secondary, 500);
$theme-accent: mat.define-palette($mat-accent, 500);
$theme-warn: mat.define-palette($mat-warn, 500);
$theme-success: mat.define-palette($suceess, 500);

$bluetheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      secondary : $theme-secondary,
      accent: $theme-accent,
      warn: $theme-warn,
      success: $theme-success,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Plus Jakarta Sans",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
        $headline-6: mat.define-typography-level(18px, 26px, 600),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

.bg-primary {
  background: mat.get-color-from-palette($theme-primary, 500) !important;
}

.bg-floralwhite {
  background: floralwhite !important;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bb_media_card {
  height: 200px;
  border-radius: 10px;
}

.bb_media_description {
  white-space: wrap;
  width: 600px;
}

div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
  background-color: rgba(202, 97, 97, 0.247) !important;
}

.cdk-overlay-dark-backdrop {  
  background-color: rgba(202, 97, 97, 0.247) !important;
}

.bb-video-previous-container {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}

.bb-navbar-style {
  box-shadow: 0px 0px 20px 0px #e3e3e3, -20px 1px 15px -15px #9f9f9f;
}

input[type=file] {
  padding: 10px;
  border-radius: 18px;
  background: rgba(202, 97, 97, 0.247);
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #D8315B;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #D8315B;
}